@import "../../scss/mixins";

.wrapper {
  @include screen-desktop {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 112px;
    margin-bottom: 112px;
  }
}

.contactTitle {
  line-height: 22px;
  letter-spacing: 0.25em;
}

.infoBlock {
  padding: 56px 51px 60px;
  background-color: #efe8e1;

  @include screen-desktop {
    max-width: 380px;
    padding: 56px 51px 60px;
  }
}

.mapWrapper {
  margin-top: -29px;
  padding: 0px 45px 57px;

  @include screen-desktop {
    margin-top: 0px;
    padding: 0;

    iframe {
      width: 652px;
      height: 535px;
    }
  }
}

.listAddress {
  margin-top: 26px;
  margin-bottom: 36px;

  .itemAddress {
    display: flex;
    align-items: center;

    p {
      line-height: 17px;
      letter-spacing: 0.25em;
    }

    div {
      &:first-child {
        margin-right: 23px;
      }
    }

    &:nth-child(1) {
      margin-bottom: 18px;
    }

    &:nth-child(2) {
      margin-bottom: 22px;
    }
  }
}

.socialMediaList {
  display: flex;
  gap: 20px;
}

.phoneLinks {
  text-decoration: none;
  color: var(--greenTextColor);
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.25em;
}

.contactInfoBlock {
  display: flex;
  flex-direction: column;
}
