.accordionWrapper {
  overflow: hidden;
}

.accordionHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  // background-color: #ebedf0;
  transition: 0.3s;

  .arrrowIcon {
    width: 14px;
    height: 8px;
    transition: 0.35s;
    transform: rotate(180deg);
  }

  .accodrionTitle {
    letter-spacing: 0.2em;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    margin-bottom: 0;
    color: #475f7b;
    transition: 0.3s;
  }

  &.active {
    // background-color: #5a8dee;

    .arrrowIcon {
      transform: rotate(0deg);
    }

    // .accodrionTitle {
    // color: #fff;
    // }
  }
}

.accordionBody {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 18px 0px 18px 15px;
}

.accodrionCollapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;

  &.show {
    height: auto;
  }
}

.accordionSection {
  margin-bottom: 21px;

  &:last-child {
    margin-bottom: 0;
  }
}
