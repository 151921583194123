.wrapper {
  padding: 73px 26px;

  @media screen and (min-width: 1440px) {
    display: flex;
    align-items: center;
    background-color: #efe8e1;
    margin-top: 150px;
    padding: 131px 60px 100px;
  }
}

.certificateTextBlock {
  @media screen and (min-width: 1440px) {
    flex: 1;
    margin-right: 56px;
  }
}

.swiperWrapper {
  margin-top: 25px;

  @media screen and (min-width: 1440px) {
    flex: 1;
    overflow: hidden;
  }
}

.swiperPagination {
  margin-top: 25px;
}

.certificateTitle {
  letter-spacing: 0.25em;
  line-height: 25px;
  margin-bottom: 16px;

  @media screen and (min-width: 1440px) {
    margin-bottom: 49px;
  }
}

.descriptionItem {
  line-height: 20px;
  letter-spacing: 0.2em;
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  @media screen and (min-width: 1440px) {
    margin-bottom: 38px;
  }
}
