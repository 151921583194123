.mobilePriceWrapper {
  @media screen and (min-width: 1440px) {
    display: none;
  }
}

.desktopPriceWrapper {
  display: flex;

  @media screen and (max-width: 1439px) {
    display: none;
  }
}

.wrapper {
  padding: 53px 25px;
  background-color: #efe8e1;

  @media screen and (min-width: 1440px) {
    background-color: #ffffff;
  }
}

.mainOptionBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 43px;
  margin-top: 35px;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 30px;
}

.subMainOptionBlock {
  position: relative;
  align-self: center;
  margin: auto;
  padding: 0 13px;
}

.underline {
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  top: 16px;
  background-color: var(--greenTextColor);

  @media screen and (min-width: 1440px) {
    top: 28px;
  }
}

.subservicesWrapper {
  :global(.swiper-slide) {
    width: auto;
  }
}

.stripIconStyles {
  margin-right: 7px;
}

.tableDataName {
  padding-left: 20px;
  padding-bottom: 15px;
  padding-right: 10px;

  &:last-of-type {
    padding-bottom: 0px;
  }
}

.tableDataPrice {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  padding-right: 10px;
}

.currentServiceLine {
  display: flex;
  align-items: center;
  width: max-content;
}

.paginationHidden {
  visibility: hidden;
  margin-top: 0px;
}

.priceListWrapper {
  margin-top: 20px;
  background-color: #fff;
  padding-bottom: 25px;
  padding-top: 20px;

  :global(.swiper-slide) {
    height: auto;
  }
}

.tablePriceList {
  width: 100%;
  tr {
    line-height: 20px;
  }
}

.customSelect {
  margin-bottom: 30px;
}

.accordionBlock {
  min-width: 25%;
}

.servicesBlock {
  overflow: hidden;
  margin-left: 25px;
}

.mainCategoriesBlock {
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
}

.subMainOptionBlockDesktop {
  position: relative;
  padding: 0 43px;
  cursor: pointer;

  p {
    line-height: 20px;
    letter-spacing: 0.25em;
  }
}

.pageDesktopPrice {
  display: flex;
  justify-content: space-between;
  background-color: #efe8e1;
  padding: 30px 38px 39px;
  column-gap: 67px;

  .pageDesktopPriceItem {
    flex: 1;

    & > div {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .priceInfo {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2em;

      p {
        &:first-child {
          margin-right: 20px;
        }

        &:last-child {
          white-space: nowrap;
        }
      }
    }
  }
}

.accordionWrapper {
  margin-top: 122px;
}

.accordionContent {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2em;
}

.subCategorieBlock {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.accordionPanelWrapper {
  div {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.desktopTitle {
  line-height: 22px;
  letter-spacing: 0.25em;
}
