@import "../../scss/mixins";

.wrapper {
  background-color: var(--base-background);
  padding: 50px 25px 100px 24px;

  @include screen-desktop {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.imageDesktopHeaderWrapper {
  margin-bottom: -52px;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @include screen-before-desktop {
    display: none;
  }
}

.preTitle {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.2em;

  @include screen-desktop {
    font-size: 48px;
    line-height: 58px;
  }
}

.title {
  line-height: 58px;
  font-size: 48px;
  letter-spacing: 0.3em;
  margin-top: 11px;

  @include screen-desktop {
    margin-top: 18px;
  }
}

.textBlockHeader {
  @include screen-desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.subtitleList {
  margin-top: 25px;

  p {
    line-height: 20px;
    letter-spacing: 0.2em;

    @include screen-desktop {
      line-height: 25px;
    }
  }
}
