.wrapper {
  padding: 72px 25px;
  @media screen and (min-width: 1440px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 152px 168px;
  }
}

.aboutUsImage {
  margin-top: 19px;
  width: 100%;
  height: auto;
  object-fit: cover;

  @media screen and (min-width: 1440px) {
    margin-top: 0px;
  }
}

.title {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.25em;
  color: var(--greenTextColor);
  margin-bottom: 21px;

  @media screen and (min-width: 1440px) {
    color: var(--baseTextColor);
    margin-bottom: 24px;
  }
}

.description {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15em;
  color: var(--greenTextColor);
  margin-bottom: 12px;

  @media screen and (min-width: 1440px) {
    color: var(--baseTextColor);
    letter-spacing: 0.2em;
    margin-bottom: 0px;
  }
}

.textWrapper {
  @media screen and (min-width: 1440px) {
    background-color: var(--greenTextColor);
    max-width: 494px;
    min-height: 482px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    position: relative;
    margin-right: -120px;
  }
}
