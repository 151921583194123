$sizeXs: 10px;
$sizeS: 12px;
$sizeM: 14px;
$sizeL: 18px;
$sizeXl: 24px;
$sizeXxl: 48px;

$weightLight: 300;
$weightNormal: 400;
$weightSemiBold: 600;
$weightBold: 700;