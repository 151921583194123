:root {
  // Text Color
  --baseTextColor: #ffffff;
  --greenTextColor: #1b4332;
  --greyTextColor: #a7a7a7;
  --peachTextColor: #efe8e1;
  // Font Family
  --primary-font: "Lato", sans-serif;
  // Background
  --base-background: #d6b095;
}

* {
  scroll-behavior: smooth;
}
